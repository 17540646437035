import React, { useState } from 'react';
import BoostersCard from '../Booster';
import { priceSignals } from '../helpers/constants';
import {
  getCurrentSignalFunds,
  getStepStateFromDB,
  stepIndexToAmount,
  getFundsFromDB,
} from '../helpers/runPriceSignals';
import { apiRequest, priceSignalsBody, updateUrl } from '../helpers/requests';
import Game from './Game';
import { getRandomDeal } from '../Deal';
import OrientationWrapper from '../helpers/OrientationWrapper';

const PriceSignals = (params) => {
  const defaultActive = 'factory';
  const initFunds = params.initFunds || 0;
  const getInitStepsFromDB = () => {
    if (!!params.gameData['price_signal_1']) {
      const { energy, ...others } = getStepStateFromDB(params.gameData);
      return { ...others };
    }
    return null;
  };
  const gameRunDBData = getInitStepsFromDB();

  const setRemainingFunds = (stepState) => {
    if (!!gameRunDBData) return getFundsFromDB(params.gameData);
    else
      return getCurrentSignalFunds(
        stepState,
        boosterState.selected,
        dealState.selected
      );
  };

  const [boosterState, setBoosterState] = useState({
    isActive: false,
    selected: null,
  });
  const [dealState, setDealState] = useState({
    isActive: false,
    selected: null,
  });

  const onControlIndexChange = (newIndex) => {
    if (newIndex === 4 && !dealState.selected)
      setDealState({ ...dealState, isActive: true });
    if (newIndex === 6 && !boosterState.selected)
      setBoosterState({ ...boosterState, isActive: true });
  };

  const onBoostersClose = (boosterId) => {
    setBoosterState({ isActive: false, selected: boosterId });
  };

  const onCloseDeal = (dealId) => {
    setDealState({ isActive: false, selected: dealId });
  };

  const onEndGame = (stepState) => {
    if (!!gameRunDBData)
      return (window.location.href = encodeURI(params.redirect));

    const body = priceSignalsBody(
      stepState,
      boosterState.selected,
      dealState.selected
    );

    apiRequest({
      method: 'PATCH',
      url: updateUrl,
      body: body,
      callback: (data) => {
        return (window.location.href = encodeURI(params.redirect));
      },
    });
  };

  return (
    <OrientationWrapper>
      <Game
        componentKey={'pricesignals'}
        defaultActive={defaultActive}
        leftOnly={true}
        controlsInfo={priceSignals}
        gameRunDBData={gameRunDBData}
        initPoints={20}
        tutorialAtStart={!gameRunDBData}
        initFunds={initFunds}
        setRemainingFunds={setRemainingFunds}
        handleControlIndexChange={onControlIndexChange}
        handleEndGameClick={onEndGame}
        showChildren={boosterState.isActive || dealState.isActive}
        stepIndexToAmount={stepIndexToAmount}
        {...params}
      >
        {boosterState.isActive && <BoostersCard onClose={onBoostersClose} />}
        {dealState.isActive && getRandomDeal(onCloseDeal, 3)}
      </Game>
    </OrientationWrapper>
  );
};

export default PriceSignals;
