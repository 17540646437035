import classNames from 'classnames';
import React from 'react';
import classes from '../styles/components/Card.module.sass';

const Card = (params) => {
  const actionsStyle = classNames(classes.cardActions);
  const cardStyle = classNames('aCard', params.class, classes.card, {
    [classes.infoCard]: params.variant == 'info',
    [classes.mainCard]: params.variant == 'main',
  });

  return (
    <div className={cardStyle} style={{ ...params.style }}>
      {!!params.handleOnClose && (
        <div className={classes.closeIcon} onClick={params.handleOnClose}>
          {'\u2715'}
        </div>
      )}
      <div className={classes.cardContent}>{params.children}</div>
      {!!params.withActions && (
        <div className={actionsStyle}>{params.actions}</div>
      )}
    </div>
  );
};

export default Card;
