import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const locales = {
  de: {
    onboarding: require('../../../public/locales/de/onboarding.json'),
    start: require('../../../public/locales/de/start.json'),
    common: require('../../../public/locales/de/common.json'),
    pricesignals: require('../../../public/locales/de/pricesignals.json'),
    investments: require('../../../public/locales/de/investments.json'),
    results: require('../../../public/locales/de/results.json'),
  },
};

i18n.use(initReactI18next).init({
  lng: 'de',
  fallbackLng: 'de',
  resources: locales,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
