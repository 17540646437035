import { boosterMap, dealMap } from './constants';

const apiRequest = async ({
  url = '/game_runs',
  method = 'GET',
  body = {},
  callback = () => {},
}) => {
  const response = await fetch(url, {
    method: method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ game_run: body }),
  });
  const data = await response.json();
  if (data) {
    callback(data);
  }
};

const updateUrl = '/game_runs/update';
const urlWithId = (id) => `/game_runs/${id}`;

const priceSignalsBody = (stepState, boosterId, dealId) => {
  const boostersBD = Object.values(boosterMap).reduce(
    (acc, curr, index) => ({ ...acc, [curr]: index + 1 === boosterId }),
    {}
  );
  const dealBD = Object.values(dealMap).reduce(
    (acc, curr, index) => ({ ...acc, [curr]: index + 1 === dealId }),
    {}
  );
  return {
    ...boostersBD,
    ...dealBD,
    price_signal_1: stepState.factory,
    price_signal_2: stepState.car,
    price_signal_3: stepState.truck,
    price_signal_4: stepState.building,
    price_signal_5: stepState.plane,
    price_signal_6: stepState.animal,
  };
};

export { apiRequest, updateUrl, urlWithId, priceSignalsBody };
