import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import warning from '../images/warning.svg';
import classes from '../styles/components/Alert.module.sass';

const Alert = (params) => {
  const { title, description, handleClose, blockClose } = params;
  const { t } = useTranslation();

  const [state, setState] = useState(true);

  const classList = classNames('componentWrapper', 'anAlert', classes.alert, {
    [classes.active]: state,
    [classes.fixed]: !!blockClose,
  });

  const handleClick = () => {
    if (handleClose) handleClose();
    setState(!state);
  };

  return (
    <div className={classList}>
      <div className={classes.content}>
        <img src={warning} />
        <div className={classes.contentTexts}>
          <div className={classes.title}>{title}</div>
          <div className={classes.description}>{description}</div>
        </div>
      </div>
      {!blockClose && (
        <div className={classes.action} onClick={handleClick}>
          {t('common:alert:action')}
        </div>
      )}
    </div>
  );
};

export default Alert;
