import classNames from 'classnames';
import React from 'react';
import classes from '../styles/components/Paragraph.module.sass';
import parse from 'html-react-parser';

const Paragraph = (params) => {
  const styling = classNames(classes.paragraph, params.variant, {
    [classes.small]: params.variant == 'small',
  });

  return (
    <div className={styling}>
      <h1>{params.title}</h1>
      <ParagraphLines lines={params.lines} variant={params.variant} />
    </div>
  );
};

const ParagraphLines = (params) => (
  <React.Fragment>
    {params.lines.map((line, i) => (
      <p key={`line_${i}`}>{parse(line)}</p>
    ))}
  </React.Fragment>
);

export default Paragraph;
export { ParagraphLines };
