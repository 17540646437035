import React from 'react';
import classNames from 'classnames';
import classes from '../styles/components/Dots.module.sass';

const Dot = ({ active }) => {
  const classList = classNames(classes.dot, {
    [classes.asActive]: active,
  });

  return <span className={classList}></span>;
};

const Dots = (params) => {
  return (
    <div className={classNames('dotsRow', classes.dots)}>
      {[...Array(params.slidesLength).keys()].map((number) => (
        <Dot key={`dot_${number}`} active={params.activeIndex >= number} />
      ))}
    </div>
  );
};

export default Dots;
