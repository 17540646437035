import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import classes from '../styles/components/Slider.module.sass';
import Button from './Button';
import Dots from './Dots';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SliderContent = (params) => {
  const { width, translate, transition, children } = params;

  const style = {
    width: `${width}px`,
    transform: `translateX(-${translate}px)`,
    transition: `transform ease-out ${transition}s`,
  };

  return (
    <div className={classes.sliderContent} style={style}>
      {children}
    </div>
  );
};

const getWidth = (elementRef = '.mainComponent') => {
  const mainElement = document.querySelector(elementRef);
  return mainElement ? mainElement.offsetWidth : window.innerWidth;
};

const Slider = (params) => {
  const { t } = useTranslation();
  const resizeRef = useRef();
  const elementRef = params.elementWidthRef || '.mainComponent';
  const translateMofifier = params.translateMofifier || 0;

  useEffect(() => {
    resizeRef.current = handleResize;
    resizeRef.current();
  }, []);

  useEffect(() => {
    const resize = () => {
      resizeRef.current();
    };
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  });

  const handleResize = () => {
    setState({
      ...state,
      translate: getWidth(elementRef) * activeIndex,
    });
  };

  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: 0.45,
  });

  const { translate, transition, activeIndex } = state;

  const nextSlide = () => {
    if (activeIndex === params.slides.length - 1 && !!params.handleNextAtEnd) {
      return params.handleNextAtEnd();
    }
    if (activeIndex === params.slides.length - 1) {
      return setState({
        ...state,
        translate: 0,
        activeIndex: 0,
      });
    }
    setState({
      ...state,
      activeIndex: activeIndex + 1,
      translate: (activeIndex + 1) * getWidth(elementRef) + translateMofifier,
    });

    if (params.handleIndexChange) {
      params.handleIndexChange(activeIndex + 1);
    }
  };

  const prevSlide = () => {
    if (activeIndex === 0) {
      return setState({
        ...state,
        translate:
          (params.slides.length - 1) * getWidth(elementRef) + translateMofifier,
        activeIndex: params.slides.length - 1,
      });
    }

    const translateNotFirst =
      activeIndex === 1
        ? 0
        : (activeIndex - 1) * getWidth(elementRef) + translateMofifier;

    setState({
      ...state,
      activeIndex: activeIndex - 1,
      translate: translateNotFirst,
    });

    if (params.handleIndexChange) {
      params.handleIndexChange(activeIndex - 1);
    }
  };

  const actionClasses = classNames('anActionRow', classes.actionsRow);

  const setBackButton = () => {
    const clickEvent = params.handleBackButton || prevSlide;
    return <Button handleClick={clickEvent} name={t('common:back')} />;
  };

  const actionButtons = (
    <div className={classes.buttonsRow}>
      {!!params.backButton && setBackButton()}
      <Button
        handleClick={nextSlide}
        variant="secondary"
        name={t('common:next')}
      />
    </div>
  );

  const invisibleButtons = (
    <React.Fragment>
      <div className={classes.sliderInvisibleLeft} onClick={prevSlide}><div className={classes.sliderInvisibleButton}>&lt;</div></div>
      <div className={classes.sliderInvisibleRight} onClick={nextSlide}><div className={classes.sliderInvisibleButton}>&gt;</div></div>
    </React.Fragment>
  );

  const classList = classNames(classes.slider, params.class);

  return (
    <div className={classList}>
      {params.invisibleButtons && invisibleButtons}
      <SliderContent
        translate={translate}
        transition={transition}
        width={getWidth(elementRef) * params.slides.length}
      >
        {params.slides}
      </SliderContent>
      <div className={actionClasses}>
        {!params.invisibleButtons && actionButtons}
        <Dots slidesLength={params.slides.length} activeIndex={activeIndex} />
      </div>
    </div>
  );
};

Slider.propTypes = {
  backButton: PropTypes.bool,
  handleBackButton: PropTypes.func,
  handleNextAtEnd: PropTypes.func,
  slides: PropTypes.array.isRequired,
  handleIndexChange: PropTypes.func,
  elementWidthRef: PropTypes.string,
  invisibleButtons: PropTypes.bool,
};

SliderContent.propTypes = {
  width: PropTypes.number.isRequired,
  translate: PropTypes.number.isRequired,
  transition: PropTypes.number.isRequired,
};

export default Slider;
