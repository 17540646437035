import React, { useState } from 'react';
import Slider from '../Slider';
import Button from '../Button';
import { BrowserRouter, useHistory } from 'react-router-dom';
import Paragraph from '../Paragraph';
import { useTranslation } from 'react-i18next';
import Chart, { LegendModal } from '../Chart';
import classNames from 'classnames';

import logo1 from '../../images/logo_1.svg';
import logo2 from '../../images/logo_2.png';
import mainTitle from '../../images/main_title.svg';
import OrientationWrapper from '../helpers/OrientationWrapper';
import Background from '../Background';

const HomeView = (params) => {
  const { t } = useTranslation();
  return (
    <div className="onBoarding-homeView">
      <img className="onBoarding-homeView-title" src={mainTitle} />
      <div className="anActionRow">
        <Button
          variant="secondary"
          handleClick={params.onClick}
          name={t('onboarding:start game')}
        />
      </div>
    </div>
  );
};

const SlideWrapper = (params) => {
  const { t } = useTranslation();
  const { index, linesNumber, children } = params;
  const slidePrefix = `onboarding:slide ${index}`;

  const linesList = () => {
    if (!linesNumber) {
      return [t(`${slidePrefix}:description`)];
    } else {
      return [...Array(linesNumber).keys()].map((i) =>
        t(`${slidePrefix}:description ${i + 1}`)
      );
    }
  };

  return (
    <div className={`onBoarding-paragraph slide-${index}`}>
      <Paragraph title={t(`${slidePrefix}:title`)} lines={linesList()} />
      {children}
    </div>
  );
};

const Onboarding = (params) => {
  const { t } = useTranslation();
  const [state, setState] = useState(params.state || 'home');
  const [chartState, setChartState] = useState(false);
  const history = useHistory();

  const goToSliderState = () => {
    history.push('/');
    setState('slider');
  };

  const handleLegendClick = (index) => setChartState(index);

  const slidesList = [
    <SlideWrapper key={'slide_1'} index={1} linesNumber={2} />,
    <SlideWrapper key={'slide_2'} index={2} />,
    <SlideWrapper key={'slide_3'} index={3} linesNumber={3} />,
    <SlideWrapper key={'slide_4'} index={4}>
      <Chart class="main-chart" handleLegendClick={handleLegendClick} />
    </SlideWrapper>,
  ];

  const logoBlock = (index, logoSrc) => (
    <div className="logosRow-block">
      <h4>{t(`onboarding:logos:title ${index}`)}</h4>
      <div className="logosRow-block-img">
        <img src={logoSrc} />
      </div>
    </div>
  );

  const onNextAtEnd = () => (window.location.href = params.redirect);
  const sliderStyling = classNames({
    ['asBackgroundOnly']: !!chartState,
  });

  return (
    <OrientationWrapper>
      <div className={classNames('mainComponent', 'onBoarding', state)}>
        {state === 'home' && <HomeView onClick={goToSliderState} />}
        {state === 'home' && (
          <div className="logosRow">
            {logoBlock(1, logo1)}
            {logoBlock(2, logo2)}
          </div>
        )}
        {state === 'slider' && (
          <Slider
            slides={slidesList}
            handleNextAtEnd={onNextAtEnd}
            backButton={true}
            class={sliderStyling}
          />
        )}
        {!!chartState && (
          <LegendModal
            index={chartState}
            handleClose={() => setChartState(false)}
          />
        )}
      </div>
      <Background />
    </OrientationWrapper>
  );
};

const OnboardingWrapper = (params) => {
  return (
    <BrowserRouter>
      <Onboarding {...params} />
    </BrowserRouter>
  );
};

export default OnboardingWrapper;
