import classNames from 'classnames';
import React from 'react';
import classes from '../styles/components/Button.module.sass';

const getClassList = (params) => {
  return classNames(classes.button, params.class, {
    [classes.secondary]: params.variant == 'secondary',
    [classes.primaryWhite]: params.variant == 'primary--white',
    [classes.green]: params.variant == 'green',
    [classes.disabled]: params.isDisabled,
    [classes.withIcon]: !!params.icon,
    [classes.asLink]: params.asLink,
  });
};

const getWithIconContent = (params) => (
  <div>
    <img src={params.icon} />
    {params.name}
  </div>
);

const Button = (params) => {
  const classList = getClassList(params);
  const btnIcon = getWithIconContent(params);

  return (
    <button
      className={classList}
      onClick={params.handleClick}
      disabled={params.isDisabled}
    >
      {!params.icon && params.name}
      {!!params.icon && btnIcon}
    </button>
  );
};

const ButtonLink = (params) => {
  const classList = getClassList({ ...params, asLink: true, class: 'asLink' });
  const btnIcon = getWithIconContent(params);

  return (
    <a
      className={classList}
      href={params.href || '#'}
      target={params.sameTab ? '_self' : '_blank'}
    >
      {!params.icon && params.name}
      {!!params.icon && btnIcon}
    </a>
  );
};

export { ButtonLink };
export default Button;
