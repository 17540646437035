import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonLink } from '../Button';
import Card from '../Card';
import { governmentResults, priceSignals } from '../helpers/constants';
import {
  getInvestmentsStepStateFromDB,
  totalEmissionsDetailed,
} from '../helpers/runInvestments';
import Paragraph, { ParagraphLines } from '../Paragraph';
import { Corner, LowerCorner, UpperCorner } from '../PathCorners';
import { AvatarCard, MainAvatar } from './Start';

import upperBackground from '../../images/results_upper.svg';
import lowerBackground from '../../images/results_lower.svg';

import { getFundsDetailsFromDB } from '../helpers/runPriceSignals';
import OrientationWrapper from '../helpers/OrientationWrapper';
import Background from '../Background';

const barHeight = (value, total, refHeight = 3.6) => {
  const newHeight = Math.round((value / total) * refHeight * 10) / 10;
  return { height: `${Math.abs(newHeight)}rem` };
};
const formattedAmount = (value) => Math.round(value);

const getExtrasIds = (gData) => {
  const deals = [...Array(6).keys()].reduce(
    (accum, curr) =>
      !!gData[`deal_${curr + 1}`] ? [...accum, curr + 1] : accum,
    []
  );
  const boosterId = [...Array(6).keys()].reduce(
    (accum, curr) => (!!gData[`booster_${curr + 1}`] ? curr + 1 : accum),
    null
  );
  return [Math.min(...deals), Math.max(...deals), boosterId];
};

const titleByResult = (totalER, oldIndex) => {
  const { t } = useTranslation();
  const difference = governmentResults.forecast - totalER.all.total;
  const randomIndex = oldIndex || Math.floor(Math.random() * 3) + 1;
  let textType = '';
  if (Math.abs(difference) < 150) textType = 'neutral';
  else {
    if (difference < 0) textType = 'positive';
    else textType = 'negative';
  }
  return [t(`results:result title:${textType}:${randomIndex}`), randomIndex];
};

const SummarySubtitles = ({ sectionKey, style, titleOnly }) => {
  const { t } = useTranslation();
  return (
    <div className="results-summary-subtitle" style={style}>
      <h4>{t(`results:${sectionKey}:title`)}</h4>
      {!titleOnly && <p>{t(`results:${sectionKey}:subtitle`)}</p>}
    </div>
  );
};

const SummaryGraphs = ({ bars, sectionKey, barsNumbers, noSum }) => {
  const { t } = useTranslation();
  return (
    <div className="results-summary-graphs">
      {bars}
      <div className={classNames('titles', { sum: !noSum })}>
        <div>
          <p>{t(`results:${sectionKey}:bar 1`)}</p>
          {t('results:number format', {
            number: formattedAmount(barsNumbers[0]),
          })}
        </div>
        <div>
          <p>{t(`results:${sectionKey}:bar 2`)}</p>
          {t('results:number format', {
            number: formattedAmount(barsNumbers[1]),
          })}
        </div>
        <div>
          <p>{t(`results:${sectionKey}:bar 3`)}</p>
          {t('results:number format', {
            number: formattedAmount(barsNumbers[2]),
          })}
        </div>
      </div>
    </div>
  );
};

const Results = (params) => {
  const gameData = params.gameData;

  if (!gameData['price_signal_1'] || !gameData['investment_1']) {
    return (window.location.href = '/start');
  }

  const stepState = getInvestmentsStepStateFromDB(gameData);
  const totalER = totalEmissionsDetailed(stepState, gameData);
  const priceSignalsDetails = getFundsDetailsFromDB(gameData);
  const [psDealId, invDealId, boosterId] = getExtrasIds(gameData);
  const { t } = useTranslation();

  const [state, setState] = useState('results');
  const [avatarState, setAvatarState] = useState(gameData['avatar_id']);

  const [mainTitle, mainTitleIndex] = titleByResult(totalER);
  const titleRef = useRef(mainTitle);
  const barClassList = (value, valueType = 'abroad', bClass) =>
    classNames('bar', bClass, valueType, {
      negative: value[valueType] < 0,
    });

  const toShareView = () => {
    if (avatarState !== gameData['avatar_id']) {
      return encodeURI(`/share?avatar=${avatarState}&title=${mainTitleIndex}`);
    }
    return encodeURI(`/share?title=${mainTitleIndex}`);
  };

  const CardHeader = ({ titleKey, descriptionKey }) => (
    <div className="results-init-paragraph">
      <Corner
        horizontal="right"
        width="unset"
        vOffset={-10}
        hOffset={-1}
        class="mobileOnly"
      >
        <img src={upperBackground} />
      </Corner>
      <Paragraph
        title={t(`results:${titleKey}`)}
        lines={descriptionKey ? [t(`results:${descriptionKey}`)] : []}
      />
    </div>
  );

  const CardFooter = () => (
    <div className="results-footer">
      <Corner
        vertical="bottom"
        width="unset"
        vOffset={-3}
        hOffset={-2}
        class="mobileOnly"
      >
        <img src={lowerBackground} />
      </Corner>
    </div>
  );

  const BarsRow = ({ values }) => (
    <div className="bars">
      <div className={barClassList(values, 'total', 'bar--de')}></div>
      <div
        className={barClassList(values, 'local')}
        style={barHeight(values.local, values.total)}
      ></div>
      <div
        className={barClassList(values)}
        style={barHeight(values.abroad, values.total)}
      ></div>
    </div>
  );

  const detailsCardExtras = (
    <div className="details-summary-extras">
      <div className="extra">
        <h4>{t('results:extras:deal:title')}</h4>
        <p className="description">
          {t(`results:extras:deal:deal ${psDealId}:description`)}
        </p>
        <p className="effect">
          {t(`results:extras:deal:deal ${psDealId}:effect`)}
        </p>
        <p className="description">
          {t(`results:extras:deal:deal ${invDealId}:description`)}
        </p>
        <p className="effect">
          {t(`results:extras:deal:deal ${invDealId}:effect`)}
        </p>
      </div>
      <div className="extra">
        <h4>{t('results:extras:booster:title')}</h4>
        <p className="description">
          {t(`results:extras:booster:booster ${boosterId}:description`)}
        </p>
        <p className="effect">
          {t(`results:extras:booster:booster ${boosterId}:effect`)}
        </p>
      </div>
    </div>
  );

  const shareLinkCard = (key, variant, description) => {
    const text = (section) => t(`results:${key} card:${section}`);
    return (
      <Card class="results-share">
        <h2>{text('title')}</h2>
        {!!description && <ParagraphLines lines={[text('description')]} />}
        <ButtonLink
          name={text('button')}
          variant={variant || null}
          href={text('link')}
        />
      </Card>
    );
  };

  const corners = [
    <UpperCorner key="up" borderWidth={3} class="results-corner" />,
    <LowerCorner key="low" borderWidth={3} class="results-corner" />,
  ];

  const mainCard = (
    <React.Fragment>
      <CardHeader titleKey="title" descriptionKey="description" />
      <Card class="results-summary">
        {corners}
        <h2>{titleRef.current}</h2>
        <MainAvatar
          avatarId={avatarState}
          handleClick={() => setState('resultsAvatar')}
        />
        <SummarySubtitles sectionKey="comparison" />
        <SummaryGraphs
          bars={
            <div className="bars">
              <div className="bar bar--br goal"></div>
              <div className="bar bar--br forecast"></div>
              <div
                className="bar"
                style={barHeight(totalER.all.total, governmentResults.goal)}
              ></div>
            </div>
          }
          sectionKey="comparison"
          barsNumbers={[
            governmentResults.goal,
            governmentResults.forecast,
            totalER.all.total,
          ]}
          noSum={true}
        />
        <SummarySubtitles
          sectionKey="total details"
          style={{ marginTop: '5rem' }}
        />
        <SummaryGraphs
          bars={<BarsRow values={totalER.all} />}
          sectionKey="total details"
          barsNumbers={[
            totalER.all.total,
            totalER.all.local,
            totalER.all.abroad,
          ]}
        />
        <div className="results-summary-actions">
          <Button
            name={t('results:actions:share')}
            variant="green"
            handleClick={() => {
              return (window.location.href = toShareView());
            }}
          />
          <Button
            name={t('results:actions:to details')}
            handleClick={() => setState('details')}
          />
        </div>
      </Card>
      {shareLinkCard('share', 'secondary', true)}
      {shareLinkCard('measures')}
      <div className="results-section">
        <Button
          name={t('results:play again:button')}
          handleClick={() => {
            return (window.location.href = '/start');
          }}
        />
      </div>
      <CardFooter />
    </React.Fragment>
  );

  const detailsMap = (detailsData, sectionKey, detailsKeys) =>
    Object.keys(detailsKeys || detailsData).map((key) => {
      const values = detailsData[key];
      return (
        <div className="details-graphBlock" key={key}>
          <h4>{t(`results:details:${sectionKey}:${key}`)}</h4>
          <SummaryGraphs
            bars={<BarsRow values={values} />}
            sectionKey="details"
            barsNumbers={[values.total, values.local, values.abroad]}
          />
        </div>
      );
    });

  const invDetailsKeys = () => {
    const { all, school, ...rest } = totalER;
    return { ...rest };
  };

  const detailsCard = (
    <React.Fragment>
      <CardHeader titleKey="details:title" />
      <Card class="details-summary">
        <UpperCorner borderWidth={3} width="15%" />
        <LowerCorner borderWidth={3} width="15%" />
        {detailsCardExtras}
      </Card>
      <Card class="results-summary">
        {corners}
        <SummarySubtitles sectionKey="details:pricesignals" />
        {detailsMap(priceSignalsDetails, 'pricesignals', priceSignals)}
      </Card>
      <Card class="results-summary">
        {corners}
        <SummarySubtitles sectionKey="details:investments" />
        {detailsMap(totalER, 'investments', invDetailsKeys())}
      </Card>
      <div className="results-section">
        <Button
          name={t('common:back')}
          handleClick={() => setState('results')}
        />
      </div>
      <CardFooter />
    </React.Fragment>
  );

  const onBackToMain = (saveAvatar) => {
    setState('results');
    if (!saveAvatar) {
      setAvatarState(gameData['avatar_id']);
    }
  };

  const onSelectAvatar = (avatarId) => {
    setAvatarState(avatarId);
  };

  return (
    <OrientationWrapper>
      <div className={classNames('mainComponent', state)}>
        {state == 'results' && mainCard}
        {state == 'resultsAvatar' && (
          <AvatarCard
            selectedIndex={avatarState}
            handleBackToMain={onBackToMain}
            handleAvatarClick={onSelectAvatar}
            title={t('results:avatar:title')}
            description={t('results:avatar:description')}
          />
        )}
        {state == 'details' && detailsCard}
      </div>
      <Background />
    </OrientationWrapper>
  );
};

export { SummaryGraphs, SummarySubtitles, barHeight, titleByResult };
export default Results;
