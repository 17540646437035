import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from '../styles/components/Map.module.sass';

const Badge = (params) => {
  const { t } = useTranslation();
  const {
    id,
    width,
    x,
    y,
    fillId,
    tailType,
    gTranslate,
    posM,
    handleClick,
    points,
    amount,
    componentKey,
  } = params;

  const tailD = () => {
    switch (tailType) {
      case 'left':
        return <path fill="#ffbf1e" d={`M${posM}l-6 4.47 6 4.273z`}></path>;

      case 'right':
        return <path fill="#ffa223" d={`M${posM}l6 4.47-6 4.273z`}></path>;

      default:
        return (
          <path fill={`url(#${fillId})`} d={`M${posM}l5.004-6 4.785 6z`}></path>
        );
    }
  };

  const text = t(`${componentKey}:${id}:map title`);
  const titleLines = text.split('\n').length;
  const [initHeight, initRy] = titleLines == 1 ? [20, 5] : [32, 7];

  const getDimensions = () => {
    if (!amount) return [width, initHeight];
    const newWidth = tailType == 'right' ? width : Math.max(width + 10, 80);
    if (componentKey === 'pricesignals' && points) {
      return [newWidth, initHeight + 30];
    } else {
      return [newWidth, initHeight + 20];
    }
  };

  const [currWidth, currHeight] = getDimensions();

  return (
    <g
      transform={`translate(${gTranslate})`}
      id={id}
      className={classes.bubble}
      onClick={() => handleClick(id)}
    >
      <rect
        width={currWidth}
        height={currHeight}
        x={x}
        y={y}
        fill={`url(#${fillId})`}
        fillRule="evenodd"
        ry={initRy}
      ></rect>
      <Content
        id={id}
        x={x}
        y={y}
        points={points}
        amount={amount}
        componentKey={componentKey}
      />
      {tailD()}
    </g>
  );
};

const Content = ({ id, x, y, points, amount, componentKey }) => {
  const { t } = useTranslation();
  const text = t(`${componentKey}:${id}:map title`);

  const titleLines = text.split('\n').map((line, i) => (
    <text
      key={`title_${id}_${i + 1}`}
      x={x + 6}
      y={y + 14 + 12 * i}
      className={classes.bubbleTitle}
    >
      {line}
    </text>
  ));

  const contentPadding = 18 + 12 * titleLines.length;

  const formattedPoints = points && componentKey === 'pricesignals' && (
    <text x={x + 6} y={y + contentPadding + 12} className={classes.bubbleText}>
      {`-${points} ${t('common:points')}`}
    </text>
  );

  const formatAmountMap =
    componentKey === 'pricesignals' ? 'map amount' : 'step format';

  const formattedAmount = amount && (
    <text x={x + 6} y={y + contentPadding} className={classes.bubbleText}>
      {t(`${componentKey}:${id}:${formatAmountMap}`, {
        number: Math.round(amount),
      })}
    </text>
  );

  return (
    <React.Fragment>
      {titleLines}
      {formattedAmount}
      {formattedPoints}
    </React.Fragment>
  );
};

export default Badge;
