import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from '../styles/components/BarHeader.module.sass';
import HelpIcon from './HelpIcon';

const Bar = (params) => {
  const blocksNumberLeft = params.blocksLeft || 6;
  const blocksNumberRight = params.blocksRight || 7;

  const activeLeft = params.leftOnly ? params.activeLeft : blocksNumberLeft;

  const blocks = (number, rightBlock) =>
    [...Array(number).keys()].map((n) => {
      const isActive = rightBlock ? params.activeRight > n : activeLeft > n;
      const key = rightBlock ? `right_${n}` : `left_${n}`;

      return <BarBlock key={key} active={isActive} rightBlock={rightBlock} />;
    });

  const barStyling = classNames(classes.bar, {
    [classes.leftOnly]: params.leftOnly,
  });

  return (
    <div className={barStyling}>
      <div className={classes.leftHalf}>{blocks(blocksNumberLeft, false)}</div>
      {!params.leftOnly && (
        <div className={classes.rightHalf}>
          {blocks(blocksNumberRight, true)}
        </div>
      )}
    </div>
  );
};

const BarBlock = (params) => {
  const blockStyling = classNames(classes.barBlock, {
    [classes.barBlockActive]: params.active,
    [classes.rightBlock]: params.rightBlock,
  });

  return <div className={blockStyling}></div>;
};

const FundsBox = (params) => {
  const { t } = useTranslation();
  const formattedAmount = t('common:header:funds format', {
    number: Math.round(params.amount),
  });

  const amountStyling = classNames(classes.fundsBoxAmount, {
    [classes.fundsBoxAmountNegative]: params.amount < -0.9,
  });

  return (
    <div className={classes.containerFundsBox}>
      <div className={classes.fundsBox}>
        <div className={classes.fundsBoxCoin}>{t('common:header:coin')}</div>
        <div className={amountStyling}>{formattedAmount}</div>
      </div>
      <div className={classes.texts}>{t('common:header:climate funds')}</div>
    </div>
  );
};

const BarHeader = (params) => {
  const { t } = useTranslation();
  const { isBackgroundOnly, handleHelpIconClick, fundsAmount } = params;
  const styling = classNames(classes.barHeader, {
    ['asBackgroundOnly']: isBackgroundOnly,
  });

  return (
    <div className={styling}>
      <div className={classes.container}>
        <HelpIcon variant={'header'} handleClick={handleHelpIconClick} />
        <Bar {...params} />
        <div className={classes.texts}>
          <div>{t('common:header:price signals')}</div>
          <div>{t('common:header:investments')}</div>
        </div>
      </div>
      <FundsBox amount={fundsAmount} />
    </div>
  );
};

export default BarHeader;
