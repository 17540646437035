import React from 'react';
import Badge from './Badge';
import { investmentsMap, priceSignalsMap } from './helpers/constants';
import { InvMap, MapWrapper, PsMap } from './MapSvgs';

const Map = (params) => {
  const {
    stepState,
    stepIndexToAmount,
    isBackgroundOnly,
    handleIconClick,
    componentKey,
  } = params;

  const [dataMap, MapSvg] =
    componentKey === 'pricesignals'
      ? [priceSignalsMap, PsMap]
      : [investmentsMap, InvMap];

  const badges = Object.keys(dataMap).map((signal) => {
    const signalObj = dataMap[signal];
    const points =
      stepState && stepState[signal] > 1 ? stepState[signal] : null;
    const amount = stepIndexToAmount(stepState[signal], signal);

    return (
      <Badge
        key={signal}
        id={signal}
        tailType={signalObj.tail}
        handleClick={handleIconClick}
        points={points}
        amount={amount}
        componentKey={componentKey}
        {...signalObj}
      />
    );
  });

  return (
    <MapWrapper>{!isBackgroundOnly && <MapSvg>{badges}</MapSvg>}</MapWrapper>
  );
};

export default Map;
