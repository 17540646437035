import React from 'react';
import Card from './Card';
import HelpIcon from './HelpIcon';
import Slider from './Slider';
import classes from '../styles/components/Tutorial.module.sass';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ParagraphLines } from './Paragraph';

import map from '../images/tutorial/map.png';
import points from '../images/tutorial/points.png';
import control from '../images/tutorial/control.png';
import fundsBox from '../images/tutorial/fundsBox.png';
import hourglass from '../images/tutorial/hourglass.svg';
import classNames from 'classnames';

const TutorialCard = (params) => {
  const { title, description, handleOnClose, children } = params;

  return (
    <Card handleOnClose={handleOnClose}>
      <div className={classes.tutorial}>
        <div>
          <strong>{title}</strong>
          <ParagraphLines lines={[description]} />
        </div>
        {children}
      </div>
    </Card>
  );
};

const CardImage = (params) => {
  let imgSrc = null;
  let unsetWidth = false;

  switch (params.index) {
    case 2:
      imgSrc = map;
      break;

    case 3:
      imgSrc = points;
      break;

    case 4:
      imgSrc = control;
      break;

    case 5:
      imgSrc = hourglass;
      break;

    case 6:
      imgSrc = fundsBox;
      unsetWidth = true;
      break;

    default:
      return <HelpIcon variant={'big'} />;
  }
  return (
    <div
      className={classNames(classes.tutorialImg, {
        [classes.unsetWidth]: unsetWidth,
      })}
    >
      <img src={imgSrc} />
    </div>
  );
};

const Tutorial = (params) => {
  const { t } = useTranslation();
  const SLIDES_NUMBER = 6;

  const slideContent = (index, section) =>
    t(`pricesignals:tutorial:slide ${index + 1}:${section}`);

  const cards = [...Array(SLIDES_NUMBER).keys()].map((i) => (
    <TutorialCard
      key={`tutorial_${i}`}
      title={slideContent(i, 'title')}
      description={slideContent(i, 'description')}
      handleOnClose={params.handleNextAtEnd}
    >
      <CardImage index={i + 1} />
    </TutorialCard>
  ));

  return (
    <Slider
      slides={cards}
      backButton={true}
      handleNextAtEnd={params.handleNextAtEnd}
    />
  );
};

export default Tutorial;

Tutorial.propTypes = {
  handleCloseButton: PropTypes.func,
  handleNextAtEnd: PropTypes.func,
};
