import {
  investStepsPercentage,
  investmentsBase,
  priceSignals,
  boosters,
  investmentSignalMap,
  investDBMap,
  speedLimitDeals,
} from './constants';
import {
  getStepAmountFromDB,
  getChosenBooster,
  getMaxSavedEmissionsLocal,
  getStepStateFromDB,
  getMaxGasReduction,
  getMaxSavedEmissionsAbroad,
  getFundsFromDB,
} from './runPriceSignals';

const getControlsInfo = (initFunds) => {
  return Object.keys(investmentsBase).reduce(
    (accum, curr) => ({
      ...accum,
      [curr]: {
        ...investmentsBase[curr],
        steps: investSteps(initFunds, investmentsBase[curr]),
      },
    }),
    {}
  );
};

const getChosenSpeedLimitDeal = (data) => {
  if (data['deal_4']) return speedLimitDeals.speedLimitRejected;
  if (data['deal_5']) return speedLimitDeals.speedLimitPassed;
  return null;
};

const investSteps = (initFunds, investment) => {
  const fullPercent = initFunds * investment.distributionFactor;
  return investStepsPercentage.map((factor) => factor * fullPercent);
};

const getInvestmentsStepStateFromDB = (data) =>
  Object.keys(investmentsBase).reduce(
    (accum, curr) => ({ ...accum, [curr]: data[investDBMap[curr]] }),
    {}
  );

const stepToAmount = (initFunds, stepState, investment) => {
  const stepsList = getControlsInfo(initFunds)[investment].steps;
  const stepIndex = stepState[investment] - 1;
  return stepsList[stepIndex];
};

const stepIndexToAmount = (initFunds, stepIndex, investment) => {
  const stepsList = getControlsInfo(initFunds)[investment].steps;
  return stepsList[stepIndex - 1];
};

// ===================== EXCEL CALCULATION ======================================

const windTurbineReductionShare = (selectedAmount) => {
  const reductionShareAssumption = 0.18;
  const avoidanceCosts = 100;
  return ((reductionShareAssumption * selectedAmount) / avoidanceCosts) * 1000;
};

const windTurbineReduction = (selectedAmount, selectedBooster) => {
  const reductionShareAssumption = 0.18;
  const fuelSwitchInvestment = reductionShareAssumption * selectedAmount;
  const reductionShare = windTurbineReductionShare(selectedAmount);
  const investmentREfactor =
    selectedBooster === 'electricityMarket' ? 0.5 : 0.75;
  const investmentRE =
    (selectedAmount - fuelSwitchInvestment) / investmentREfactor;

  const investmentRERatio = (ratio, factor) => investmentRE * ratio * factor;

  const electricitySources = {
    solar: investmentRERatio(0.4, 4.6),
    windOnShore: investmentRERatio(0.28, 9.4),
    windOffShore: investmentRERatio(0.32, 6.2),
  };
  const eSourcesTotal = Object.values(electricitySources).reduce(
    (total, curr) => total + curr
  );

  const gHGasReductionAssumption = 0.0004;
  const gHGasReduction = eSourcesTotal * gHGasReductionAssumption * 1000;
  const totalGHGasReduction = gHGasReduction + reductionShare;

  // TODO: do we need this?
  // const additionalJobsPerBillion = 5320;
  // const totalJobs = (investRE / 10) * additionalJobsPerBillion;
  return totalGHGasReduction;
};

const companyReduction = (selectedAmount, dbData) => {
  const mitigationCostsAssumption = 300;
  const factorySignalAmount = getStepAmountFromDB(dbData, 'factory');
  const averagePriceCO2 = factorySignalAmount - 60;
  const averageCostCfDs = mitigationCostsAssumption - averagePriceCO2;
  const cfDsReduction = (selectedAmount / averageCostCfDs) * 1000;
  return cfDsReduction;
};

const trainReduction = (selectedAmount, selectedDeal) => {
  const avoidanceCosts = 600;
  const emissionSavings = (selectedAmount / avoidanceCosts) * 1000;
  const savingsFromDeal = selectedDeal ? selectedDeal.amount : 0;
  return emissionSavings + savingsFromDeal;
};

const shipReduction = (maxSavedEmissionsLocal) => {
  const { baselineLocal, baselineAbroad } = priceSignals.truck;
  const totalBaseline = baselineAbroad + baselineLocal;

  const investmentAbroad =
    (baselineAbroad / totalBaseline) * maxSavedEmissionsLocal;
  const investmentLocal =
    (baselineLocal / totalBaseline) * maxSavedEmissionsLocal;
  return [investmentLocal, investmentAbroad];
};

const cityReduction = (selectedAmount) => {
  // const previousInvestment = 70;
  const taxRateAssumption = 3; // 300%
  const additionalInvestments = taxRateAssumption * selectedAmount;
  const investSources = {
    // energyMasterPlans: additionalInvestments * .3,
    buildingInsulation: additionalInvestments * 0.2,
    heatingRenewal: additionalInvestments * 0.2,
    infraestructure: additionalInvestments * 0.3,
  };
  const avoidanceCosts = {
    buildingInsulation: 400,
    heatingRenewal: 70,
    infraestructure: 300,
  };
  const totalGHGasSavings = Object.keys(investSources)
    .map(
      (source) => (investSources[source] / avoidanceCosts[source]) * 100 * 5.5
    )
    .reduce((total, curr) => total + curr, 0);
  return totalGHGasSavings;
};

const schoolDeductionFactor = (initFunds, stepState) => {
  const stepsList = getControlsInfo(initFunds).school.steps;
  const selectedStepIndex = stepState.school - 1;
  const maxStepIndex = 4;

  return (stepsList[selectedStepIndex] / stepsList[maxStepIndex]) * 0.75 + 0.25;
};

const subTotalEmissions = (stepState, investment, dbData) => {
  const initFunds = getFundsFromDB(dbData);
  const selectedAmount = stepToAmount(initFunds, stepState, investment);
  const selectedBooster = getChosenBooster(dbData);
  const selectedDeal = getChosenSpeedLimitDeal(dbData);
  const maxSavedEmissionsLocal = getMaxSavedEmissionsLocal(
    getStepStateFromDB(dbData),
    investmentSignalMap[investment],
    selectedBooster
  );

  const calculateEmissions = () => {
    switch (investment) {
      case 'windTurbine':
        const reductionShare = windTurbineReductionShare(selectedAmount);
        return [
          windTurbineReduction(selectedAmount, selectedBooster),
          Math.max(0, reductionShare - maxSavedEmissionsLocal),
        ];

      case 'company':
        const reduction = companyReduction(selectedAmount, dbData);
        const maxGasReduction = getMaxGasReduction(
          investmentSignalMap[investment]
        );

        if (selectedBooster == 'cfDs') {
          return [
            Math.min(reduction, maxSavedEmissionsLocal),
            -maxGasReduction * (1 - boosters.borderAdjustment.factor),
          ];
        } else if (selectedBooster == 'borderAdjustment') {
          return [
            maxGasReduction * boosters.borderAdjustment.factor,
            Math.max(0, reduction - maxSavedEmissionsLocal),
          ];
        } else {
          return [
            maxGasReduction * boosters.borderAdjustment.factor,
            -maxGasReduction * (1 - boosters.borderAdjustment.factor),
          ];
        }

      case 'train':
        return [trainReduction(selectedAmount, selectedDeal), 0];

      case 'ship':
        return shipReduction(maxSavedEmissionsLocal);

      case 'city':
        return [cityReduction(selectedAmount), 0];

      case 'park':
        return [
          maxSavedEmissionsLocal,
          getMaxSavedEmissionsAbroad(
            getStepStateFromDB(dbData),
            investmentSignalMap[investment]
          ),
        ];
      default:
        return [0, 0];
    }
  };

  const [localEmissions, abroadEmissions] = calculateEmissions();
  return { local: localEmissions, abroad: abroadEmissions };
};

const totalEmissionsDetailed = (stepState, dbData) => {
  const initFunds = getFundsFromDB(dbData);
  const schoolFactor = schoolDeductionFactor(initFunds, stepState);
  const allEmissions = Object.keys(investmentsBase).map((invKey) => {
    return {
      ...subTotalEmissions(stepState, invKey, dbData),
      invKey: invKey,
    };
  });

  return Object.keys(allEmissions)
    .map((inv) => {
      const invObj = allEmissions[inv];
      switch (invObj.invKey) {
        case 'park':
          return {
            ...invObj,
            abroad: invObj.abroad * schoolFactor,
            local: invObj.local * schoolFactor,
          };

        case 'city':
          return invObj;

        case 'windTurbine':
          return {
            ...invObj,
            local: invObj.local * schoolFactor * 0.9,
          };

        default:
          return { ...invObj, local: invObj.local * schoolFactor };
      }
    })
    .reduce(
      (accum, invObj) => ({
        ...accum,
        all: {
          local: accum.all.local + invObj.local,
          abroad: accum.all.abroad + invObj.abroad,
          total: accum.all.total + invObj.local + invObj.abroad,
        },
        [invObj.invKey]: {
          local: invObj.local,
          abroad: invObj.abroad,
          total: invObj.local + invObj.abroad,
        },
      }),
      { all: { local: 0, abroad: 0, total: 0 } }
    );
};

export {
  getControlsInfo,
  getInvestmentsStepStateFromDB,
  stepIndexToAmount,
  totalEmissionsDetailed,
};
