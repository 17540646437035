import {
  allSignals,
  boosters,
  signalMap,
  boosterMap,
  deals,
  dealMap,
} from './constants';

const getFundsFromDB = (data) => {
  const stepState = getStepStateFromDB(data);
  return totalRevenueWithDeals(
    stepState,
    getChosenBooster(data),
    getChosenDeal(data)
  );
};

const getFundsDetailsFromDB = (data) => {
  const stepState = getStepStateFromDB(data);
  return additionalRevenueDetails(stepState, getChosenBooster(data));
};

const getCurrentSignalFunds = (stepState, boosterId, dealId) => {
  const fullStepState = { ...stepState, energy: stepState.factory };
  const booster = !!boosterId ? Object.keys(boosterMap)[boosterId - 1] : null;
  const deal = !!dealId ? Object.keys(dealMap)[dealId - 1] : null;
  return totalRevenueWithDeals(fullStepState, booster, deal);
};

const getStepStateFromDB = (data) =>
  Object.keys(allSignals).reduce(
    (accum, curr) => ({ ...accum, [curr]: data[signalMap[curr]] }),
    {}
  );

const getChosenBooster = (data) =>
  Object.keys(boosters).reduce(
    (accum, curr) => (!!data[boosterMap[curr]] ? curr : accum),
    null
  );

const getChosenDeal = (data) =>
  Object.keys(deals).reduce(
    (accum, curr) => (!!data[dealMap[curr]] ? curr : accum),
    null
  );

const stepToAmount = (stepState, signal) => {
  const stepsList = allSignals[signal].steps;
  const stepIndex = stepState[signal] - 1;
  return stepsList[stepIndex];
};

const stepIndexToAmount = (stepIndex, signal) => {
  const stepsList = allSignals[signal].steps;
  return stepsList[stepIndex - 1];
};

const getStepAmountFromDB = (data, signal) => {
  return stepToAmount(getStepStateFromDB(data), signal);
};

// ===================== EXCEL CALCULATION ======================================

// Maximale Treibhausgasemissonsreduktion bis 2030
const getMaxGasReduction = (signal) => {
  if (signal == 'truck' || signal == 'animal') {
    return null;
  }
  const { baselineLocal, baselineAbroad, maxGasEmision } = allSignals[signal];
  return (baselineLocal + baselineAbroad - maxGasEmision) * 5.5;
};

// Reduktionsfaktor Stellschraube
const getReductionFactor = (stepState, signal) =>
  stepToAmount(stepState, signal) / allSignals[signal].steps[4];

const getBoosterFactor = (signal, chosenBooster) => {
  let factor = 1;
  Object.keys(boosters).map((bName) => {
    // factor applies only if booster IS NOT selected
    if (boosters[bName].affects.includes(signal) && bName !== chosenBooster) {
      factor *= boosters[bName].factor;
    }
  });
  return factor;
};

// Eingestellte maximale eingesparte Emissionen bis 2030 vor Ausgaben Inland
const getMaxSavedEmissionsLocal = (stepState, signal, chosenBooster) => {
  const maxGasReduction = getMaxGasReduction(signal);
  const boosterFactor = getBoosterFactor(signal, chosenBooster);
  const reductionFactor = getReductionFactor(stepState, signal);

  const maxGasReductModifier =
    (allSignals[signal].baselineLocal + allSignals[signal].baselineAbroad) *
      10 -
    maxGasReduction;

  switch (signal) {
    case 'truck':
      return allSignals.truck.baselineLocal * 5.5 * 0.5 * reductionFactor;

    case 'animal':
      return allSignals.animal.baselineLocal * 5.5 * 0.5 * reductionFactor;

    case 'plane':
      return maxGasReductModifier * reductionFactor;

    case 'car':
      return maxGasReductModifier * reductionFactor;

    case 'building':
      return maxGasReductModifier * reductionFactor * boosterFactor;

    // factory & energy
    default:
      return maxGasReductModifier * boosterFactor;
  }
};

// Eingestellte maximale eingesparte Emissionen bis 2030 vor Ausgaben Ausland
const getMaxSavedEmissionsAbroad = (stepState, signal) => {
  const reductionFactor = getReductionFactor(stepState, signal);
  if (signal == 'truck' || signal == 'animal') {
    return allSignals[signal].baselineAbroad * reductionFactor * 5.5 * 0.5;
  }
  return 0;
};

// Mehreinnahmen mit Faktoren bis 2030 Gesamt
const additionalRevenueWithFactors = (stepState, signal, chosenBooster) => {
  const savedEmissionsLocal = getMaxSavedEmissionsLocal(
    stepState,
    signal,
    chosenBooster
  );
  const savedEmissionsAbroad = getMaxSavedEmissionsAbroad(stepState, signal);
  const savedEmissions = savedEmissionsLocal + savedEmissionsAbroad;
  const selectedStepValue = stepToAmount(stepState, signal);

  switch (signal) {
    case 'truck':
      return (savedEmissions * selectedStepValue) / 1000;

    case 'animal':
      return (savedEmissions * selectedStepValue) / 1000;

    case 'plane':
      const subsidiesFactor = 1 - 0.1 * stepState.plane;
      return selectedStepValue * subsidiesFactor * 10;

    case 'car':
      const avgMileage = 644;
      const carReductionFactor = 1 - 0.05 * stepState.car;
      return (
        (selectedStepValue * avgMileage * 5.5 * carReductionFactor) / 100 -
        50 * 5.5
      );

    default:
      // for energy, factory and building
      return (savedEmissionsLocal * selectedStepValue) / 1000;
  }
};

// Mehreinnahmen mit Faktoren bis 2030 Gesamt
const additionalRevenueTotal = (stepState, chosenBooster) => {
  return Object.keys(allSignals)
    .map((entityKey) => {
      if (!stepState[entityKey]) return 0;
      return additionalRevenueWithFactors(stepState, entityKey, chosenBooster);
    })
    .reduce((accum, curr) => accum + curr, 0);
};

const additionalRevenueDetails = (stepState, chosenBooster) => {
  return Object.keys(allSignals)
    .map((entityKey) => {
      if (!stepState[entityKey]) return 0;
      const total = additionalRevenueWithFactors(
        stepState,
        entityKey,
        chosenBooster
      );
      if (entityKey == 'truck' || entityKey == 'animal') {
        const abroadOnly = getMaxSavedEmissionsAbroad(stepState, entityKey);
        const selectedStepValue = stepToAmount(stepState, entityKey);
        const abroad = (abroadOnly * selectedStepValue) / 1000;
        return {
          key: entityKey,
          values: {
            total: total,
            abroad: abroad,
            local: total - abroad,
          },
        };
      } else {
        return {
          key: entityKey,
          values: { total: total, abroad: 0, local: total },
        };
      }
    })
    .reduce((accum, item) => ({ ...accum, [item.key]: item.values }), {});
};

// Summe Einnahmen
const totalRevenueWithDeals = (stepState, chosenBooster, chosenDeal) => {
  const revenueSubTotal = additionalRevenueTotal(stepState, chosenBooster);
  const planeRevenue =
    additionalRevenueWithFactors(stepState, 'plane', chosenBooster) || 0;
  // before any deal is selected
  if (!chosenDeal) return revenueSubTotal;

  switch (chosenDeal) {
    case 'coronaFundBudget':
      return deals[chosenDeal].amount + (revenueSubTotal + planeRevenue);

    case 'pppProjects':
      return deals[chosenDeal].amount + (revenueSubTotal + planeRevenue);

    case 'corruptionFair':
      return deals[chosenDeal].amount * planeRevenue + revenueSubTotal;

    default:
      return revenueSubTotal + planeRevenue;
  }
};

// percentage in excel sheet, but do we use it at all??
// const getSharePercentage = (stepState, signal, chosenBooster) => {
//   const totalSum = Object.keys(allSignals)
//     .map(
//       (entityKey) =>
//         getMaxSavedEmissionsLocal(stepState, entityKey, chosenBooster) +
//         getMaxSavedEmissionsAbroad(stepState, entityKey)
//     )
//     .reduce((accum, curr) => accum + curr);

//   return (
//     (getMaxSavedEmissionsLocal(stepState, signal, chosenBooster) +
//       getMaxSavedEmissionsAbroad(stepState, signal)) /
//     totalSum
//   );
// };

export {
  getFundsFromDB,
  getFundsDetailsFromDB,
  getCurrentSignalFunds,
  getStepAmountFromDB,
  getChosenBooster,
  getMaxSavedEmissionsLocal,
  getMaxSavedEmissionsAbroad,
  getStepStateFromDB,
  getMaxGasReduction,
  stepIndexToAmount,
};
