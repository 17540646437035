import React from 'react';

const UpperCorner = (params) => {
  const pathD =
    'M0 38.754c2.196 1.656 11.001 9.66 24.585 6.502 13.584-3.158 5.363-21.183 15.295-27.839C50.869 10.053 58.893 14.636 56.609.063L10.013 0C4.49-.007.007 4.464-.001 9.987V38.754z';
  return <Corner {...params} pathD={pathD} unit={'px'} />;
};

const LowerCorner = (params) => {
  const pathD =
    'M57 7.232C54.804 5.577 45.999-2.428 32.415.73 18.831 3.888 27.052 21.913 17.12 28.57 6.131 35.933-1.893 31.35.391 45.922l46.596.063c5.523.007 10.006-4.463 10.014-9.986V7.232z';
  return (
    <Corner
      {...params}
      pathD={pathD}
      vertical="bottom"
      horizontal="right"
      unit={'px'}
    />
  );
};

const Corner = (params) => {
  const {
    pathD,
    style,
    width,
    borderWidth,
    vertical,
    horizontal,
    unit,
    vOffset,
    hOffset,
  } = params;
  const styling = {
    ...style,
    position: 'absolute',
    maxWidth: width || '20%',
    [vertical || 'top']: `${-borderWidth || vOffset || 0}${unit || 'rem'}`,
    [horizontal || 'left']: `${-borderWidth || hOffset || 0}${unit || 'rem'}`,
  };

  return (
    <React.Fragment>
      {pathD && (
        <svg viewBox="0 0 57 45.986" style={styling} className={params.class}>
          <path fill="#ff9200" fillRule="evenodd" d={pathD}></path>
        </svg>
      )}
      {!pathD && (
        <div style={styling} className={params.class}>
          {params.children}
        </div>
      )}
    </React.Fragment>
  );
};

export { UpperCorner, LowerCorner, Corner };
