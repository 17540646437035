import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import pieChartImg from '../images/pie-chart.svg';
import classes from '../styles/components/Chart.module.sass';
import Button from './Button';
import Card from './Card';
import Paragraph from './Paragraph';

const LegendModal = (params) => {
  const { index, handleClose } = params;
  const { t } = useTranslation();

  return (
    <Card
      variant="info"
      handleOnClose={handleClose}
      class={classNames(classes.legendModal, params.class)}
    >
      <Paragraph
        variant={classes.legendParagraph}
        title={t(`onboarding:pie chart:legend ${index}:title`)}
        lines={[t(`onboarding:pie chart:legend ${index}:description`)]}
      />
      <Button
        variant="secondary"
        name={t('common:close')}
        handleClick={handleClose}
      />
    </Card>
  );
};

const Chart = (params) => {
  const { t } = useTranslation();

  const legendClassList = (i) =>
    classNames('aLegendItem', classes.legendItem, classes[`legend-${i + 1}`]);

  const classList = classNames(classes.pieChart, params.class, {
    [classes.modalActive]: false,
  });

  const legend = [...Array(6).keys()].map((i) => (
    <div className={legendClassList(i)} key={`legend_${i + 1}`}>
      <div className={classes.dot}></div>
      <p onClick={() => params.handleLegendClick(i + 1)}>
        {t(`onboarding:pie chart:legend ${i + 1}:title`)}
      </p>
    </div>
  ));

  return (
    <div className={classList}>
      <img src={pieChartImg} />
      <div className={classNames(classes.legend, params.legendClass)}>
        {legend}
      </div>
    </div>
  );
};

export { LegendModal };
export default Chart;
