import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '../Alert';

const OrientationWrapper = (params) => {
  const isLandscape = () =>
    window.matchMedia('(orientation:landscape) and (max-height: 30rem)')
      .matches;
  const [orientation, setOrientation] = useState(
    isLandscape() ? 'landscape' : 'portrait'
  );
  const onWindowResize = () => {
    clearTimeout(window.resizeLag);
    window.resizeLag = setTimeout(() => {
      delete window.resizeLag;
      setOrientation(isLandscape() ? 'landscape' : 'portrait');
    }, 200);
  };

  useEffect(
    () => (
      onWindowResize(),
      window.addEventListener('resize', onWindowResize),
      () => window.removeEventListener('resize', onWindowResize)
    ),
    []
  );

  const { t } = useTranslation();

  const alertView = (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 3,
        backgroundColor: '#eaf2eb',
      }}
      className="orientationAlert"
    >
      <Alert
        title={t('common:orientation alert:title')}
        description={t('common:orientation alert:description')}
        blockClose={true}
      />
    </div>
  );

  return (
    <React.Fragment>
      {isLandscape() && alertView}
      {params.children}
    </React.Fragment>
  );
};

export default OrientationWrapper;
