import React from 'react';
import background from '../images/background.svg';

const Background = () => (
  <div className="background">
    <img src={background} />
  </div>
);

export default Background;
