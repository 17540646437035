import classNames from 'classnames';
import React from 'react';
import classes from '../styles/components/HelpIcon.module.sass';

const HelpIcon = (params) => {
  const styling = classNames(classes.helpIcon, {
    [classes.bigIcon]: params.variant == 'big',
    [classes.inHeader]: params.variant == 'header',
    [classes.basic]: params.variant == 'basic',
  });

  return (
    <div className={styling} onClick={params.handleClick}>
      <div className={classes.circle}>
        <div className={classes.questionMark}>?</div>
      </div>
    </div>
  );
};

export default HelpIcon;
