// new white icons
import company from '../../images/icons/company.svg';
import factory from '../../images/icons/factory.svg';
import plane from '../../images/icons/plane.svg';
import windTurbine from '../../images/icons/wind-turbine.svg';
import park from '../../images/icons/park.svg';
import train from '../../images/icons/train.svg';
import school from '../../images/icons/school.svg';
import car from '../../images/icons/car.svg';
import animal from '../../images/icons/animal.svg';
import building from '../../images/icons/building.svg';
import city from '../../images/icons/city.svg';
import ship from '../../images/icons/ship.svg';
import truck from '../../images/icons/truck.svg';

//energy does not appear on the map, and it follows the same steps as factory
const energySignal = {
  steps: [60, 90, 120, 150, 180],
  baselineLocal: 245,
  baselineAbroad: 0,
  maxGasEmision: 45,
};

const priceSignals = {
  factory: {
    steps: [60, 90, 120, 150, 180],
    baselineLocal: 137,
    baselineAbroad: 0,
    maxGasEmision: 50,
  },
  car: {
    steps: [6, 8, 10, 12, 14],
    baselineLocal: 98,
    baselineAbroad: 0,
    maxGasEmision: 40,
  },
  truck: {
    steps: [90, 120, 150, 180, 210],
    baselineLocal: 38.5,
    baselineAbroad: 20,
    maxGasEmision: 0,
  },
  building: {
    steps: [60, 90, 120, 150, 180],
    baselineLocal: 122,
    baselineAbroad: 0,
    maxGasEmision: 70,
  },
  plane: {
    steps: [6, 16, 26, 36, 46],
    baselineLocal: 100,
    baselineAbroad: 0,
    maxGasEmision: 0,
    position: ['bottom', 'right'],
  },
  animal: {
    steps: [60, 90, 120, 150, 180],
    baselineLocal: 55,
    baselineAbroad: 62,
    maxGasEmision: 0,
  },
};

const priceSignalsMap = {
  factory: {
    width: 75,
    x: 479.73,
    y: 261.49,
    fillId: 'f',
    gTranslate: '-100.35 188.85',
    posM: '507.83 261.57',
    tail: 'up',
  },
  car: {
    width: 66,
    x: 479.73,
    y: 260.49,
    fillId: 'g',
    gTranslate: '-339.36 79.6',
    posM: '507.33 260.57',
    tail: 'up',
  },
  truck: {
    width: 81,
    x: 479.73,
    y: 261.49,
    fillId: 'h',
    gTranslate: '-216.27 293.85',
    posM: '515.33 261.57',
    tail: 'up',
  },
  building: {
    width: 89,
    x: 479.73,
    y: 247.49,
    fillId: 'i',
    gTranslate: '-94 2',
    posM: '519.33 247.35',
    tail: 'up',
  },
  plane: {
    width: 98,
    x: -321.24,
    y: 245.86,
    fillId: 'j',
    gTranslate: '482.978 225.073',
    posM: '-321.06 257.98',
    tail: 'left',
  },
  animal: {
    width: 91,
    x: -321.24,
    y: 258.15,
    fillId: 'j',
    gTranslate: '560.978 -19.401',
    posM: '-321.06 264.13',
    tail: 'left',
  },
};

const boosters = {
  taxReform: {
    affects: ['energy', 'building'],
    factor: 0.9,
  },
  electricityMarket: {
    affects: ['energy'],
    factor: 0.75,
  },
  borderAdjustment: {
    affects: ['factory'],
    factor: 0.2,
  },
  cfDs: {
    affects: ['factory'],
    factor: 0.5,
  },
  speedLimit: {
    affects: ['auto'],
    factor: 1,
  },
  rentsDilemma: {
    affects: ['building'],
    factor: 0.6,
  },
};

const deals = {
  coronaFundBudget: {
    amount: 50,
    affect: null,
  },
  pppProjects: {
    amount: -100,
    affects: null,
  },
  corruptionFair: {
    amount: -0.5,
    affects: 'plane',
  },
};

const speedLimitDeals = {
  speedLimitRejected: {
    amount: 0,
    affects: 'train',
  },
  speedLimitPassed: {
    amount: 100,
    affects: 'train',
  },
};

const allSignals = { ...priceSignals, energy: energySignal };

const signalMap = {
  factory: 'price_signal_1',
  energy: 'price_signal_1',
  car: 'price_signal_2',
  truck: 'price_signal_3',
  building: 'price_signal_4',
  plane: 'price_signal_5',
  animal: 'price_signal_6',
};

const boosterMap = {
  taxReform: 'booster_1',
  electricityMarket: 'booster_2',
  borderAdjustment: 'booster_3',
  cfDs: 'booster_4',
  speedLimit: 'booster_5',
  rentsDilemma: 'booster_6',
};

const dealMap = {
  coronaFundBudget: 'deal_1',
  pppProjects: 'deal_2',
  corruptionFair: 'deal_3',
  speedLimitRejected: 'deal_4',
  speedLimitPassed: 'deal_5',
};

const investDBMap = {
  windTurbine: 'investment_1',
  company: 'investment_2',
  train: 'investment_3',
  ship: 'investment_4',
  city: 'investment_5',
  school: 'investment_6',
  park: 'investment_7',
};

const investStepsPercentage = [0.001, 0.25, 0.5, 0.75, 1];

const investmentsBase = {
  windTurbine: {
    distributionFactor: 0.2,
  },
  company: {
    position: ['bottom', 'right'],
    distributionFactor: 0.4,
  },
  train: {
    position: ['bottom', 'right'],
    distributionFactor: 0.4,
  },
  ship: {
    position: ['bottom', 'right'],
    distributionFactor: 0.3,
  },
  city: {
    position: ['bottom', 'left'],
    distributionFactor: 0.3,
  },
  school: {
    distributionFactor: 0.2,
  },
  park: {
    distributionFactor: 0.2,
  },
};

const investmentSignalMap = {
  windTurbine: 'energy',
  company: 'factory',
  train: 'car',
  ship: 'truck',
  city: 'building',
  school: 'plane',
  park: 'animal',
};

const investmentsMap = {
  windTurbine: {
    width: 84.2,
    x: 61.342,
    y: -64.572,
    fillId: 'k',
    gTranslate: '64 637',
    posM: '144.5-55.5',
    tail: 'right',
  },
  company: {
    width: 56,
    x: 76.835,
    y: -64.572,
    fillId: 'l',
    gTranslate: '300.01 514.67',
    posM: '98.976-64.501',
    tail: 'up',
  },
  train: {
    width: 147,
    x: 29.389,
    y: -64.572,
    fillId: 'm',
    gTranslate: '158.81 372.67',
    posM: '98.976-64.501',
    tail: 'up',
  },
  ship: {
    width: 76,
    x: -223.38,
    y: -27.52,
    fillId: 'n',
    gTranslate: '424.6 178.34',
    posM: '-223.2-21.538',
    tail: 'left',
  },
  city: {
    width: 110,
    x: 48.109,
    y: -64.572,
    fillId: 'o',
    gTranslate: '313.82 313.92',
    posM: '98.976-64.501',
    tail: 'up',
  },
  school: {
    width: 90,
    x: 58.165,
    y: -64.572,
    fillId: 'l',
    gTranslate: '284.62 644.5',
    posM: '98.976-64.501',
    tail: 'up',
  },
  park: {
    width: 97,
    x: 54.241,
    y: -64.572,
    fillId: 'p',
    gTranslate: '191.66 572.51',
    posM: '98.976-64.501',
    tail: 'up',
  },
};

const getIcon = (name) => {
  switch (name) {
    case 'animal':
      return animal;

    case 'building':
      return building;

    case 'truck':
      return truck;

    case 'plane':
      return plane;

    case 'car':
      return car;

    case 'windTurbine':
      return windTurbine;

    case 'park':
      return park;

    case 'train':
      return train;

    case 'school':
      return school;

    case 'factory':
      return factory;

    case 'city':
      return city;

    case 'ship':
      return ship;

    default:
      return company;
  }
};

const governmentResults = {
  goal: 1907,
  forecast: 749,
};

export {
  priceSignals,
  allSignals,
  priceSignalsMap,
  boosters,
  deals,
  speedLimitDeals,
  signalMap,
  boosterMap,
  dealMap,
  investStepsPercentage,
  investmentsBase,
  investmentsMap,
  investmentSignalMap,
  investDBMap,
  getIcon,
  governmentResults,
};
