import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Card from '../Card';
import Paragraph, { ParagraphLines } from '../Paragraph';
import editIcon from '../../images/edit-avatar.svg';
import { apiRequest } from '../helpers/requests';
import classNames from 'classnames';

import avatar1 from '../../images/avatars/avatar_1';
import avatar2 from '../../images/avatars/avatar_2';
import avatar3 from '../../images/avatars/avatar_3';
import avatar4 from '../../images/avatars/avatar_4';
import avatar5 from '../../images/avatars/avatar_5';
import avatar6 from '../../images/avatars/avatar_6';
import avatar7 from '../../images/avatars/avatar_7';
import avatar8 from '../../images/avatars/avatar_8';
import avatar9 from '../../images/avatars/avatar_9';
import avatar0 from '../../images/avatars/avatar_0';
import OrientationWrapper from '../helpers/OrientationWrapper';
import Background from '../Background';

const avatarImgs = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
];

const AvatarCard = (params) => {
  const { t } = useTranslation();
  const {
    selectedIndex,
    handleBackToMain,
    handleAvatarClick,
    title,
    description,
  } = params;
  const content = (section) => t(`start:avatar:${section}`);

  const avatarsList = avatarImgs.map((imgSrc, i) => (
    <img
      key={`avatar_${i + 1}`}
      onClick={() => handleAvatarClick(i + 1)}
      src={imgSrc}
      className={classNames({ active: i + 1 === selectedIndex })}
    />
  ));

  const noAvatar = (
    <img
      onClick={() => handleAvatarClick(null)}
      src={avatar0}
      className={classNames({ active: !selectedIndex })}
    />
  );

  return (
    <Card variant="main">
      <div className="avatarCard">
        <Paragraph
          title={title || content('title')}
          lines={[description || content('description')]}
        />
        <div className="anAvatarsRow">
          {avatarsList}
          {noAvatar}
        </div>
      </div>
      <div className="aButtonsRow">
        <Button
          handleClick={() => handleBackToMain(false)}
          name={t('common:back')}
        />
        <Button
          variant="secondary"
          handleClick={() => handleBackToMain(true)}
          name={t('common:confirm')}
        />
      </div>
    </Card>
  );
};

const MainAvatar = (params) => {
  const { avatarId, handleClick } = params;

  if (!avatarId) {
    return <div className="mainAvatar"></div>;
  }

  return (
    <div className="mainAvatar">
      <img src={avatarImgs[avatarId - 1]} />
      <img src={editIcon} className="editIcon" onClick={handleClick} />
    </div>
  );
};

const Start = (params) => {
  const { t } = useTranslation();

  const [state, setState] = useState('main');
  const [avatarState, setAvatarState] = useState(params.avatarId || 1);

  const content = (section) => t(`start:${state}:${section}`);

  const startGame = () =>
    apiRequest({
      method: 'POST',
      body: { avatar_id: avatarState },
      callback: (data) => {
        return (window.location.href = encodeURI(params.redirect));
      },
    });

  const onBackToMain = (saveAvatar) => {
    setState('main');
    if (!saveAvatar) {
      setAvatarState(1);
    }
  };

  const onSelectAvatar = (avatarId) => {
    setAvatarState(avatarId);
  };

  const mainCard = (
    <Card variant="main">
      <div>
        <h1>{content('title')}</h1>
        <MainAvatar
          avatarId={avatarState}
          handleClick={() => setState('avatar')}
        />
        <ParagraphLines
          lines={[content('description 1'), content('description 2')]}
        />
      </div>
      <Button
        variant="secondary"
        handleClick={startGame}
        name={t("common:let's go!")}
      />
    </Card>
  );

  return (
    <OrientationWrapper>
      <div className="mainComponent start">
        {state == 'main' && mainCard}
        {state == 'avatar' && (
          <AvatarCard
            selectedIndex={avatarState}
            handleBackToMain={onBackToMain}
            handleAvatarClick={onSelectAvatar}
          />
        )}
      </div>
      <Background />
    </OrientationWrapper>
  );
};

export { AvatarCard, MainAvatar };
export default Start;
