import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonLink } from '../Button';
import Card from '../Card';
import { governmentResults } from '../helpers/constants';
import { MainAvatar } from './Start';
import { useScreenshot } from 'use-react-screenshot';

import {
  SummarySubtitles,
  SummaryGraphs,
  barHeight,
  titleByResult,
} from './Results';
import OrientationWrapper from '../helpers/OrientationWrapper';
import {
  getInvestmentsStepStateFromDB,
  totalEmissionsDetailed,
} from '../helpers/runInvestments';

import facebook from '../../images/icon_facebook.svg';
import whatsapp from '../../images/icon_whatsapp.svg';
import Background from '../Background';
import classNames from 'classnames';

const getNewAvatarId = (defaultAvatar) => getParam('avatar', defaultAvatar, 10);
const getTitleId = () => getParam('title', null, 4);

const getParam = (key, defaultValue, maxValue) => {
  const queryUrl = new URLSearchParams(window.location.search);
  if (!queryUrl.has(key)) {
    return defaultValue;
  }
  const newValue = queryUrl.get(key);
  if (!isNaN(newValue) && newValue > 0 && newValue < maxValue) return newValue;
  return null;
};

const ShareCard = (params) => {
  const { t } = useTranslation();
  const gameData = params.gameData;
  const stepState = getInvestmentsStepStateFromDB(gameData);
  const totalER = totalEmissionsDetailed(stepState, gameData);
  const avatarId = getNewAvatarId(gameData['avatar_id']);
  const titleRef = titleByResult(totalER, getTitleId())[0];

  const [state, setState] = useState('main');
  const [copiedState, copiedSetState] = useState(false);

  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot();

  const getImage = () => {
    window.scrollTo(0, 0);
    takeScreenshot(ref.current);
  };

  useEffect(() => {
    getImage();
  }, []);

  const copyToClipboard = () => {
    const aux = document.createElement('textarea');
    aux.innerText = `https://${t('results:image:link')}`;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    aux.remove();
    copiedSetState(true);
    setTimeout(() => copiedSetState(false), 2000);
  };

  const htmlImgWrapper = (
    <div
      className={classNames('htmlImgWrapper', { noAvatar: !avatarId })}
      ref={ref}
    >
      <Card>
        <h4 className="mainTitle">{titleRef}</h4>
        {avatarId && <MainAvatar avatarId={avatarId} />}
        <SummarySubtitles sectionKey="comparison" />
        <SummaryGraphs
          bars={
            <div className="bars">
              <div className="bar bar--br goal"></div>
              <div className="bar bar--br forecast"></div>
              <div
                className="bar"
                style={barHeight(totalER.all.total, governmentResults.goal)}
              ></div>
            </div>
          }
          sectionKey="comparison"
          barsNumbers={[
            governmentResults.goal,
            governmentResults.forecast,
            totalER.all.total,
          ]}
          noSum={true}
        />
      </Card>
      <h5>{t('results:image:subtitle')}</h5>
      <a target="_blank" href={`https://${t('results:image:link')}`}>
        {t('results:image:link')}
      </a>
    </div>
  );

  const shareWrapper = (
    <div className="shareWrapper">
      <div
        className="exitIcon"
        onClick={() => {
          return (window.location.href = '/results');
        }}
      >
        {'\u2715'}
      </div>
      <p>{t('results:image:legend')}</p>
      <img src={image} />
      <Button
        variant="primary--white"
        name={t('results:image:share')}
        handleClick={() => setState('social')}
      />
    </div>
  );

  const socialMediaWrapper = (
    <div className="socialWrapper">
      <Card class="modal" handleOnClose={() => setState('main')}>
        <h2>{t('results:image:share modal:title')}</h2>
        <div className="socialWrapper-actions">
          <ButtonLink
            name={t('results:image:share modal:facebook')}
            icon={facebook}
            href={`https://www.facebook.com/sharer/sharer.php?u=${'https://spielregelnfuersklima.de'}`}
          />
          <ButtonLink
            name={t('results:image:share modal:whatsapp')}
            icon={whatsapp}
            href={`https://wa.me/?text=${encodeURI(
              'https://spielregelnfuersklima.de'
            )}`}
          />
        </div>
        <div className="socialWrapper-link" title={t('results:image:link')}>
          <span>{t('results:image:link')}</span>
          <Button
            variant="primary--white"
            name={t('results:image:share modal:copy link')}
            handleClick={copyToClipboard}
          />
          {copiedState && (
            <div className="copied">
              {t('results:image:share modal:copied')}
            </div>
          )}
        </div>
      </Card>
    </div>
  );

  return (
    <OrientationWrapper>
      <div className="mainComponent image">
        {!image && htmlImgWrapper}
        {image && shareWrapper}
        {state == 'social' && socialMediaWrapper}
      </div>
      {image && <Background />}
    </OrientationWrapper>
  );
};

export default ShareCard;
