import React from 'react';
import Button from './Button';
import Card from './Card';
import { ParagraphLines } from './Paragraph';
import classes from '../styles/components/Deal.module.sass';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import coronaFundBudget from '../images/deals/corona_fund_budget.svg';
import pppProjects from '../images/deals/ppp_projects.svg';
import corruptionFair from '../images/deals/corruption_fair.svg';
import speedLimitRejected from '../images/deals/speed_limit_rejected.svg';
import speedLimitPassed from '../images/deals/speed_limit_passed.svg';

const getImg = (index) => {
  switch (index) {
    case 1:
      return coronaFundBudget;

    case 2:
      return pppProjects;

    case 3:
      return corruptionFair;

    case 4:
      return speedLimitRejected;

    case 5:
      return speedLimitPassed;

    default:
      return null;
  }
};

const Deal = (params) => {
  const { t } = useTranslation();
  const classList = classNames(
    'componentWrapper',
    'dealCard',
    classes.dealCard
  );
  const { handleClick, index, componentKey } = params;

  return (
    <div className={classList} id={`deal_${index}`}>
      <Card variant="main">
        <h1>{t('pricesignals:deals:title')}</h1>
        <img src={getImg(index)} />
        <ParagraphLines lines={[t(`${componentKey}:deals:deal ${index}`)]} />
        <div className={classes.buttonsRow}>
          <Button
            name={t('common:next')}
            variant="secondary"
            handleClick={() => handleClick(params.index)}
          />
        </div>
      </Card>
    </div>
  );
};

const getRandomDeal = (handleClick, total = 3) => {
  let random = Math.floor(Math.random() * total) + 1;
  return (
    <Deal
      index={random}
      handleClick={handleClick}
      componentKey={'pricesignals'}
    />
  );
};

export default Deal;
export { getRandomDeal };
