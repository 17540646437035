import React, { useState } from 'react';
import Card from './Card';
import classes from '../styles/components/Booster.module.sass';
import Paragraph, { ParagraphLines } from './Paragraph';
import Slider from './Slider';
import { LowerCorner, UpperCorner } from './PathCorners';
import HelpIcon from './HelpIcon';
import classNames from 'classnames';
import Button from './Button';
import { useTranslation } from 'react-i18next';

import taxReform from '../images/boosters/tax_reform.svg';
import electricityMarket from '../images/boosters/electricity_market.svg';
import borderAdjustment from '../images/boosters/border_adjustment.svg';
import cfDs from '../images/boosters/cfDs.svg';
import speedLimit from '../images/boosters/speed_limit.svg';
import rentsDilemma from '../images/boosters/rents_dilemma.svg';

const getImg = (index) => {
  switch (index) {
    case 1:
      return taxReform;

    case 2:
      return electricityMarket;

    case 3:
      return borderAdjustment;

    case 4:
      return cfDs;

    case 5:
      return speedLimit;

    case 6:
      return rentsDilemma;

    default:
      return null;
  }
};

const Booster = (params) => {
  const {
    isActive,
    handleClick,
    index,
    title,
    content,
    chip,
    handleHelpClick,
  } = params;
  const classList = classNames(classes.booster, {
    [classes.asActive]: isActive,
  });

  return (
    <div className={classList} onClick={handleClick}>
      <UpperCorner borderWidth={3} />
      <LowerCorner borderWidth={3} />
      <img src={getImg(index)} />
      <div className={classes.texts}>
        <div className={classes.titleBlock}>
          <h4>{title}</h4>
          <HelpIcon variant="basic" handleClick={handleHelpClick} />
        </div>
        <ParagraphLines lines={[content]} />
      </div>
      <div className={classes.chip}>{chip}</div>
    </div>
  );
};

const BoosterInfoCard = (params) => {
  const { t } = useTranslation();
  const { handleOnClose, title, description } = params;

  return (
    <div className={classes.infoCard}>
      <Card variant="info" handleOnClose={handleOnClose}>
        <h3>{title}</h3>
        <ParagraphLines lines={description.split('\n')} />
        <Button
          variant="secondary"
          handleClick={handleOnClose}
          name={t('common:close')}
        />
      </Card>
    </div>
  );
};

const BoostersCard = (params) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [infoActive, setInfoActive] = useState(false);
  const onBoosterClick = (k) => setActive(k);

  const boosterWrapper = (k) => (
    <div
      className={classNames(classes.boosterWrapper, 'aBooster')}
      key={`booster_${k + 1}`}
    >
      <Booster
        title={t(`pricesignals:boosters:booster ${k + 1}:title`)}
        content={t(`pricesignals:boosters:booster ${k + 1}:content`)}
        chip={t(`pricesignals:boosters:booster ${k + 1}:chip`)}
        isActive={k + 1 === active}
        index={k + 1}
        handleClick={() => onBoosterClick(k + 1)}
        handleHelpClick={() => setInfoActive(k + 1)}
      />
    </div>
  );

  const boostersList = [...Array(6).keys()].map((num) => boosterWrapper(num));
  const classList = classNames(
    'componentWrapper',
    'boostersCard',
    classes.boostersCard
  );

  return (
    <div className={classList}>
      <Card variant="main">
        <Paragraph
          variant="small"
          title={t('pricesignals:boosters:title')}
          lines={[t('pricesignals:boosters:description')]}
        />
        <div className={classes.sliderWrapper}>
          <Slider
            elementWidthRef={'.aBooster'}
            invisibleButtons={true}
            slides={boostersList}
            translateMofifier={-2}
          />
        </div>
        <Button
          variant="secondary"
          name={t('pricesignals:boosters:button')}
          isDisabled={!active}
          handleClick={() => params.onClose(active)}
        />
      </Card>
      {infoActive && (
        <BoosterInfoCard
          handleOnClose={() => setInfoActive(false)}
          title={t(`pricesignals:boosters:booster ${infoActive}:title`)}
          description={t(`pricesignals:boosters:booster ${infoActive}:info`)}
        />
      )}
    </div>
  );
};

export default BoostersCard;
