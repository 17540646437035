import React, { useState } from 'react';
import Card from '../Card';
import Game from './Game';
import glueckwunsch from '../../images/glueckwunsch.svg';
import { ParagraphLines } from '../Paragraph';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import { apiRequest, updateUrl } from '../helpers/requests';
import { getChosenBooster, getFundsFromDB } from '../helpers/runPriceSignals';
import {
  getControlsInfo,
  stepIndexToAmount,
  getInvestmentsStepStateFromDB,
} from '../helpers/runInvestments';
import Deal from '../Deal';
import OrientationWrapper from '../helpers/OrientationWrapper';
import Background from '../Background';

const InitCard = (params) => {
  const { t } = useTranslation();
  const fundsFormat = Math.round(params.funds);

  return (
    <div className="mainComponent investments init">
      <Card variant="main">
        <div>
          <h1>{t('investments:init:title')}</h1>
          <img src={glueckwunsch} />
          <ParagraphLines
            lines={[
              t('investments:init:content 1', { number: fundsFormat }),
              t('investments:init:content 2'),
            ]}
          />
        </div>
        <Button
          variant="secondary"
          handleClick={params.onClose}
          name={t('investments:init:button')}
        />
      </Card>
    </div>
  );
};

const Investments = (params) => {
  const defaultActive = 'windTurbine';
  const initFunds = getFundsFromDB(params.gameData);

  if (!initFunds) {
    return (window.location.href = '/start');
  }

  const controlsInfo = getControlsInfo(initFunds);

  const getInitStepsFromDB = () => {
    return !!params.gameData['investment_1']
      ? getInvestmentsStepStateFromDB(params.gameData)
      : null;
  };
  const gameRunDBData = getInitStepsFromDB();

  const stepsToAmount = (stepState) => {
    const stepValues = { ...stepState };
    Object.keys(stepValues).map((key) => {
      const stepsList = controlsInfo[key].steps;
      stepValues[key] = stepValues[key] ? stepsList[stepValues[key] - 1] : 0;
    });
    return stepValues;
  };

  const [state, setState] = useState(!!gameRunDBData ? 'game' : 'init');
  const [dealState, setDealState] = useState({
    isActive: false,
    selected: null,
  });

  const setRemainingFunds = (stepState) => {
    const stepValues = stepsToAmount(stepState);
    return Object.values(stepValues).reduce(
      (total, current) => (current ? total - current : total),
      initFunds
    );
  };

  const onControlIndexChange = (newIndex, activeInvestment) => {
    if (activeInvestment === 'train' && !dealState.selected) {
      setDealState({ ...dealState, isActive: true });
    }
  };

  const onCloseDeal = (dealId) => {
    setDealState({ isActive: false, selected: dealId });
  };

  const onEndGame = (stepState) => {
    if (!!gameRunDBData)
      return (window.location.href = encodeURI(params.redirect));

    apiRequest({
      method: 'PATCH',
      url: updateUrl,
      body: {
        investment_1: stepState.windTurbine,
        investment_2: stepState.company,
        investment_3: stepState.train,
        investment_4: stepState.ship,
        investment_5: stepState.city,
        investment_6: stepState.school,
        investment_7: stepState.park,
        [`deal_${dealState.selected}`]: true,
      },
      callback: (data) => {
        return (window.location.href = encodeURI(params.redirect));
      },
    });
  };

  const getDeal = () => {
    const booster = getChosenBooster(params.gameData);
    const dealIndex = booster == 'speedLimit' ? 4 : 5;
    return (
      <Deal
        handleClick={onCloseDeal}
        index={dealIndex}
        componentKey={'investments'}
      />
    );
  };

  return (
    <OrientationWrapper>
      {state == 'init' && (
        <React.Fragment>
          <InitCard funds={initFunds} onClose={() => setState('game')} />
          <Background />
        </React.Fragment>
      )}
      {state == 'game' && (
        <Game
          componentKey={'investments'}
          defaultActive={defaultActive}
          leftOnly={false}
          controlsInfo={controlsInfo}
          gameRunDBData={gameRunDBData}
          initFunds={initFunds}
          setRemainingFunds={setRemainingFunds}
          handleControlIndexChange={onControlIndexChange}
          showChildren={dealState.isActive}
          handleEndGameClick={onEndGame}
          stepIndexToAmount={(index, key) =>
            stepIndexToAmount(initFunds, index, key)
          }
          {...params}
        >
          {dealState.isActive && getDeal()}
        </Game>
      )}
    </OrientationWrapper>
  );
};

export default Investments;
