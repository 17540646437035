import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import classes from '../styles/components/ControlUnit.module.sass';
import Button from './Button';
import Card from './Card';
import Paragraph from './Paragraph';
import { useTranslation } from 'react-i18next';
import { getIcon } from './helpers/constants';

const ControlSlider = (params) => {
  const { t } = useTranslation();
  const activeStep = params.activeStep || 1;
  const { steps, handleClick, showPointTag, currentPoints } = params;
  const initRef = useRef();

  const [maxState, setMaxState] = useState(false);

  useEffect(() => {
    initRef.current = params.activeStep || 0;
  }, []);

  const handleSliderClick = (i) => {
    const pointsBase = Math.max(currentPoints, 0);
    if (i < initRef.current + pointsBase || !showPointTag) {
      setMaxState(false);
      return handleClick(i + 1);
    } else {
      setMaxState(true);
    }
  };

  const sliderDots = steps.map((step, i) => {
    const maxPoints = i + 1 == steps.length && maxState;
    const classList = classNames(classes.sliderDot, {
      [classes.asActive]: i == activeStep - 1,
      [classes.maxPoints]: maxPoints,
    });

    const dotTag = (i) => {
      const tagText = maxPoints
        ? t('pricesignals:lacking points')
        : `-${i + 1} ${t('common:points')}`;

      return <div className={classes.dotTag}>{tagText}</div>;
    };

    return (
      <div
        className={classList}
        key={`dot_${i}`}
        onClick={() => handleSliderClick(i)}
      >
        {i > 0 && showPointTag && dotTag(i)}
        <div className={classes.dotAmount}>{step}</div>
      </div>
    );
  });

  const sliderStyling = classNames(
    classes.sliderBar,
    classes[`sliderBar${activeStep}`]
  );
  const topBoxStyling = classNames(classes.sliderTopBox, {
    [classes.sliderTopBoxOnly]: !showPointTag,
  });

  return (
    <div className={topBoxStyling}>
      <div className={classes.legend}>{params.legend}</div>
      <div className={classes.slider}>
        <div className={sliderStyling}>{sliderDots}</div>
      </div>
      <div></div>
    </div>
  );
};

const InfoCard = (params) => {
  const { t } = useTranslation();
  const cardContent = (section) =>
    t(`${params.componentKey}:${params.activeKey}:${section}`);

  return (
    <div className={classes.infoCard}>
      <Card handleOnClose={params.handleOnClose} variant={'info'}>
        <div>
          <h3>{cardContent('title')}</h3>
          <Paragraph
            title={t(`${params.componentKey}:what all is about`)}
            lines={[cardContent('infocard:part 1')]}
          />
          <Paragraph
            title={t(`${params.componentKey}:how it is currently`)}
            lines={[cardContent('infocard:part 2')]}
          />
          <Paragraph
            title={t(`${params.componentKey}:positive and negative effects`)}
            lines={[cardContent('infocard:part 3')]}
          />
        </div>
        <Button
          variant="secondary"
          handleClick={params.handleOnClose}
          name={t('common:close')}
        />
      </Card>
    </div>
  );
};

const ControlUnit = (params) => {
  const { t } = useTranslation();
  const totalPoints = params.totalPoints;
  const points = params.activeStep || 0;

  const classList = classNames(classes.controlUnit, {
    ['asBackgroundOnly']: params.isBackgroundOnly,
  });

  const [stateInfo, setStateInfo] = useState(false);

  const toggleInfoCard = () => {
    params.handleInfoCard(!stateInfo);
    setStateInfo(!stateInfo);
  };
  const controlContent = (section) =>
    t(`${params.componentKey}:${params.activeKey}:${section}`);

  return (
    <div className={classList}>
      <h3 className={classes.titleContainer}>
        <img src={getIcon(params.activeKey)} />
        {controlContent('title')}
      </h3>
      <div className={classes.description}>
        {controlContent('description')}
        <a onClick={toggleInfoCard}>
          <u>{t('common:learn more')}</u>
        </a>
      </div>
      {stateInfo && (
        <InfoCard
          handleOnClose={toggleInfoCard}
          activeKey={params.activeKey}
          componentKey={params.componentKey}
        />
      )}
      <ControlSlider
        {...params}
        legend={controlContent('legend')}
        showPointTag={!!totalPoints}
      />
      {!!totalPoints && (
        <div className={classes.sliderBottomBox}>
          <div>{`${points} / ${totalPoints} ${t('common:points')}`}</div>
        </div>
      )}
      <div className={classes.buttonRow}>
        <Button
          variant="primary--white"
          name={t('common:cancel')}
          handleClick={params.handleToMapClick}
        />
        <Button
          variant="secondary"
          name={t('common:confirm')}
          handleClick={params.handleNextClick}
        />
      </div>
    </div>
  );
};

export default ControlUnit;
