import React, { useEffect, useState } from 'react';
import Map from '../Map';
import BarHeader from '../BarHeader';
import ControlUnit from '../ControlUnit';
import Tutorial from '../Tutorial';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button';
import Alert from '../Alert';

const PointsChip = (params) => {
  const { t } = useTranslation();
  const { current, init, isVisible } = params;

  const classList = classNames('pointsChip', {
    isActive: isVisible,
  });

  return (
    <div className={classList}>
      <div className="pointsChip-text">
        {`${current} / ${init} ${t('common:points')}`}
      </div>
    </div>
  );
};

const Game = (params) => {
  const { t } = useTranslation();

  const initSteps =
    params.gameRunDBData ||
    Object.keys(params.controlsInfo).reduce(
      (acc, curr) => ((acc[curr] = null), acc),
      {}
    );
  const initActive = params.tutorialAtStart ? params.defaultActive : null;

  const getRemainingPoints = (newStepState) => {
    if (!!params.initPoints) {
      return Object.values(newStepState).reduce(
        (total, current) => (current ? total - current : total),
        params.initPoints
      );
    }
  };

  const initKpiState = () => {
    const newKpiState = {
      currentFunds: params.initFunds,
      currentPoints: params.initPoints,
    };
    if (!!params.gameRunDBData) {
      newKpiState.currentFunds = params.setRemainingFunds(params.gameRunDBData);
      newKpiState.currentPoints = getRemainingPoints(params.gameRunDBData);
    }
    return newKpiState;
  };

  const [controlState, setControlState] = useState({
    controlIndex: params.gameRunDBData
      ? Object.keys(params.gameRunDBData).length
      : 1,
    active: initActive,
    activeChanged: false,
    activeInitValue: null,
    visibleChildren: params.showChildren,
    infoActive: false,
  });
  const [kpiState, setKpiState] = useState(initKpiState());
  const [stepState, setStepState] = useState(initSteps);
  const [tutorialState, setTutorialState] = useState({
    active: !!params.tutorialAtStart,
    backToMap: true,
  });
  const [alertState, setAlertState] = useState({
    shown: false,
    active: false,
  });

  const {
    active,
    controlIndex,
    activeChanged,
    activeInitValue,
    visibleChildren,
  } = controlState;

  useEffect(() => {
    if (params.showChildren !== visibleChildren) {
      setControlState({
        ...controlState,
        visibleChildren: params.showChildren,
      });
      if (visibleChildren) {
        setKpiState({
          ...kpiState,
          currentFunds: params.setRemainingFunds(stepState),
        });
      }
    }
  }, [params.showChildren]);

  const onStepClick = (stepIndex) => {
    setControlState({ ...controlState, activeChanged: true });
    setStepState({ ...stepState, [active]: stepIndex });
  };

  const onToMapClick = () => {
    setControlState({ ...controlState, activeChanged: false, active: null });
    setStepState({ ...stepState, [active]: activeInitValue });
  };

  const onNextClick = () => {
    let newStepState = stepState;
    // default min value
    if (!activeChanged && !activeInitValue) {
      newStepState = { ...stepState, [active]: 1 };
      setStepState(newStepState);
    }
    const newIndex = Object.values(newStepState).reduce(
      (total, step) => (step ? total + 1 : total),
      0
    );
    setControlState({
      ...controlState,
      controlIndex: newIndex,
      activeChanged: false,
      active: null,
      activeInitValue: null,
    });
    const remainingPoints = getRemainingPoints(newStepState);
    const currentFunds = params.setRemainingFunds(newStepState);
    setKpiState({
      ...kpiState,
      currentPoints: remainingPoints,
      currentFunds: currentFunds,
    });

    if (
      remainingPoints < 0 ||
      (currentFunds < -0.9 && params.componentKey === 'investments') ||
      (isGameComplete() && remainingPoints > 0)
    ) {
      setAlertState({ ...alertState, active: true });
    }

    if (params.handleControlIndexChange) {
      params.handleControlIndexChange(newIndex, active);
    }
  };

  const onTutorialClose = () => {
    const newActive = tutorialState.backToMap ? null : active;
    setControlState({ ...controlState, active: newActive });
    setTutorialState({ ...tutorialState, active: false, backToMap: false });
  };

  const onIconClick = (iconType) => {
    if (!!params.gameRunDBData) return;

    setControlState({
      ...controlState,
      active: iconType,
      activeInitValue: stepState[iconType],
    });
    setAlertState({ ...alertState, active: false });
  };

  const onHelpIconClick = () => {
    setTutorialState({ ...tutorialState, backToMap: !active, active: true });
    const newActive = active || params.defaultActive;
    setControlState({ ...controlState, active: newActive });
  };

  const onInfoCardClick = () => {
    setControlState({ ...controlState, infoActive: !controlState.infoActive });
  };

  const isGameComplete = () => Object.values(stepState).every((step) => !!step);

  const getControlUnit = () => {
    const controlInfo = params.controlsInfo[active];

    const formattedSteps = controlInfo.steps.map((number) =>
      t(`${params.componentKey}:${active}:step format`, {
        number: Math.round(number),
      })
    );

    return (
      <React.Fragment>
        {tutorialState.active && (
          <div className="tutorialWrapper">
            <Tutorial
              handleCloseButton={onTutorialClose}
              handleNextAtEnd={onTutorialClose}
            />
          </div>
        )}
        <ControlUnit
          steps={formattedSteps}
          activeStep={stepState[active]}
          handleClick={onStepClick}
          handleToMapClick={onToMapClick}
          handleNextClick={onNextClick}
          isBackgroundOnly={tutorialState.active}
          componentKey={params.componentKey}
          activeKey={active}
          totalPoints={params.initPoints}
          currentPoints={kpiState.currentPoints}
          handleInfoCard={onInfoCardClick}
        />
      </React.Fragment>
    );
  };

  const getActions = () => {
    let actions = [];
    if (kpiState.currentFunds < -0.9 && params.componentKey === 'investments') {
      actions.push(
        <Alert
          title={t('investments:alerts:excess:title')}
          description={t('investments:alerts:excess:description')}
          key="alert_excess_funds"
          handleClose={() => setAlertState({ shown: true, active: false })}
        />
      );
    }
    if (kpiState.currentFunds === 0 && params.componentKey === 'investments' && !isGameComplete()) {
      actions.push(
        <Alert
          title={t('investments:alerts:visitInvestments:title')}
          description={t('investments:alerts:visitInvestments:description')}
          key="alert_excess_funds"
          handleClose={() => setAlertState({ shown: true, active: false })}
        />
      );
    }
    if (isGameComplete()) {
      if (kpiState.currentPoints > 0) {
        actions.push(
          <Alert
            title={t('pricesignals:alerts:remaining:title')}
            description={t('pricesignals:alerts:remaining:description')}
            handleClose={() => setAlertState({ shown: true, active: false })}
            key="alert_remaining_points"
          />
        );
      }
      const isDisabled =
        (!!kpiState.currentPoints && !alertState.shown) ||
        alertState.active ||
        kpiState.currentPoints < 0 ||
        kpiState.currentFunds < -0.9;

      const btnName = !!params.gameRunDBData
        ? 'common:next'
        : `${params.componentKey}:button completed`;
      actions.push(
        <Button
          variant="secondary"
          class="componentWrapper"
          name={t(btnName)}
          handleClick={() => params.handleEndGameClick(stepState)}
          isDisabled={isDisabled}
          key="button_end"
        />
      );
    }
    return actions;
  };

  const classList = classNames('mainComponent', params.componentKey, {
    atTop: controlState.infoActive,
  });

  return (
    <React.Fragment>
      <div className="headerWrapper">
        <BarHeader
          leftOnly={params.leftOnly}
          activeLeft={controlIndex}
          activeRight={controlIndex}
          isBackgroundOnly={tutorialState.active}
          handleHelpIconClick={onHelpIconClick}
          fundsAmount={kpiState.currentFunds}
        />
        <PointsChip
          isVisible={!active && !!params.initPoints}
          init={params.initPoints}
          current={kpiState.currentPoints}
        />
      </div>
      <div className={classList}>{!!active && getControlUnit()}</div>
      <Map
        handleIconClick={onIconClick}
        stepIndexToAmount={params.stepIndexToAmount}
        componentKey={params.componentKey}
        stepState={stepState}
        isBackgroundOnly={!!active}
      />
      {!active && getActions()}
      {params.showChildren && params.children}
    </React.Fragment>
  );
};

Game.propTypes = {
  componentKey: PropTypes.string.isRequired,
  defaultActive: PropTypes.string.isRequired,
  leftOnly: PropTypes.bool.isRequired,
  controlsInfo: PropTypes.object.isRequired,
  gameRunDBData: PropTypes.object,
  initFunds: PropTypes.number.isRequired,
  initPoints: PropTypes.number,
  tutorialAtStart: PropTypes.bool,
  showChildren: PropTypes.bool,
  setRemainingFunds: PropTypes.func.isRequired,
  handleControlIndexChange: PropTypes.func,
  handleEndGameClick: PropTypes.func.isRequired,
  stepIndexToAmount: PropTypes.func,
};

export default Game;
